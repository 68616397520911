import { useEmploymentTypes } from "@/hooks/useEmploymentTypes";
import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/jobs.json";

export const useJobs = ({ __consultant = null, __sector = null, limit = null } = {}) => {
  const employment_types = useEmploymentTypes();
  let jobs = useSourceFlowCollection(data.jobs).map((job) => {
    const json = JSON.stringify(job.categories);

    return {
      ...job,
      employment_type: employment_types.find((e) => json.includes(e.id)),
    };
  });

  if (__consultant) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__consultant));
  }

  if (__sector) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__sector));
  }

  if (limit) {
    jobs = jobs.slice(0, limit);
  }

  return jobs;
};

export default useJobs;
